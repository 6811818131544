<template>
    <v-container>
        <div class="text-h3">{{$t('why-kotlin')}}</div>
        <div style="font-size:medium; margin-bottom:40px; border-top:1px solid black;" class="nocontent">
        </div>

		<p>Kotlin is a modern programming language that makes developers happier.</p>
		<p>With the release of kotlin native, it is possible to use Topping Engine with kotlin.</p>
		<p>For more visit <a href="www.kotlinlang.com">Kotlin Lang</a></p>
        
    </v-container>
</template>

<script>
export default {
    name: "WhyKotlin"
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>