<template>
    <v-container>
        <div class="text-h3">{{$t('faq')}}</div>
        <div style="font-size:medium; margin-bottom:40px; border-top:1px solid black;" class="nocontent">
        </div>

		<p>Currently under construction</p>
        
    </v-container>
</template>

<script>
export default {
    name: "FAQ"
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>