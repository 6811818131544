import { render, staticRenderFns } from "./Installation.vue?vue&type=template&id=51efd07c&"
import script from "./Installation.vue?vue&type=script&lang=js&"
export * from "./Installation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCol,VContainer,VDivider,VIcon,VRow,VTreeview})
