<template>
    <v-container>
        <div class="text-h4">Starting with jetpack compose</div><br/>
        <p>Jetpack Compose will be the primary usage method of this library in the future. Subsequent view development will be long term support only.</p>
        <p>You can start using Topping Compose with demo project available in <a target="blank" href="https://github.com/topping-dev/topping-kotlin-sample-compose">github repository</a>.</p>
        <div class="text-h4">Differences from regular jetpack compose</div><br/>
        <p>Topping Compose library tries to preserve original approach of Jetpack Compose.</p>
        <p>There are some limitations coming from kotlin native for iOS and Mac, mainly dynamically creating class instances.</p>
        <p>Because of this some initialization methods are overriden to fix ambugity problems. If kotlin native fixes reflection methods in the future this problem will be fixed.</p>
        <p>Mostly these differences are overcomed with adding "Platform" prefix to the class name and extending iOS and Android classes.</p>
        <pre v-highlightjs><span class="kotlin">
    @Composable
    fun AScreen(navController: PlatformNavHostController) {
        Box(modifier = Modifier.fillMaxSize(),
              </span></pre><br/>
        <p>Since we don't have initializers in iOS, library has to get initializer lambdas from developer.</p>
        <pre v-highlightjs><span class="kotlin">
    @Composable
    fun App(mainViewModel: MainViewModel = viewModel(key = "MainViewModel") {
        MainViewModel()
    })
    </span></pre><br/>
    <p>Since code is multiplatform we have to initialize our library in multiplatform container</p>
    <pre v-highlightjs><span class="kotlin">
    override fun onCreate() {
        val cc = PlatformContext(getForm().getContext()!!.getNativeContext(), null)
        PlatformComposeView(cc).addThis(getForm().getNativeForm()) {
            App()
        }
    }
    </span></pre><br/>
    <p>Resources accessed via adding ".compose" suffix to autogenerated "LR" class.</p>
    <pre v-highlightjs><span class="kotlin">
        title = {
            Text(text = stringResource(LR.string.receive.compose))
        }
    </span></pre><br/>
    <p>Other than mentioned differences development is very similar to standart compose.</p>
        <NextPrevPage prev="" :next="$t('installation')" nextRoute="installation"></NextPrevPage>
    </v-container>
</template>

<script>
import OSLangSelector from '../components/OSLangSelector';
import OSSelector from '../components/OSSelector';
import NextPrevPage from '../components/NextPrevPage';
import Storage from './../storage';

export default {
    name: "Installation",
    components: {
        OSLangSelector,
        OSSelector,
        NextPrevPage
    },
    data: () => ({
        langos: "Lua-Android",
        open: ['Resources', 'assets', 'scripts', 'ui', 'layout'],
        files: 
        {
            html: 'mdi-language-html5',
            js: 'mdi-nodejs',
            json: 'mdi-code-json',
            md: 'mdi-language-markdown',
            pdf: 'mdi-file-pdf',
            png: 'mdi-file-image',
            txt: 'mdi-file-document-outline',
            xls: 'mdi-file-excel',
            lua: 'mdi-file-document-outline',
            xml: 'mdi-file-document-outline',
        },
        scriptTreeAndroid: [],
        scriptItemsAndroid: 
        [
            {
            name: 'assets',
            children: 
                [
                    {
                        name: 'scripts',
                        children: [{
                            name: 'testbed.lua',
                            file: 'lua',
                        }],
                    },
                    {
                        name: 'defines.lua',
                        file: 'lua',
                    },
                    {
                        name: 'moddebug.lua',
                        file: 'lua',
                    }
                ],
            },
        ],
        scriptTreeIOS: [],
        scriptItemsIOS: 
        [
            {
            name: 'Resources',
            children: 
                [
                    {
                        name: 'scripts',
                        children: [{
                            name: 'testbed.lua',
                            file: 'lua',
                        }],
                    },
                    {
                        name: 'ui',
                        children: 
                        [
                            {
                                name: 'drawable',
                                children: []
                            },
                            {
                                name: 'layout',
                                children: [{
                                    name: 'testbed.xml',
                                    file: 'xml',
                                }]
                            },
                            {
                                name: 'values',
                                children: []
                            },
                        ],
                    },
                    {
                        name: 'defines.lua',
                        file: 'lua',
                    },
                    {
                        name: 'moddebug.lua',
                        file: 'lua',
                    }
                ],
            },
        ]
    }),
    mounted() {
        var vm = this;
		Storage.getLang(function(lang)
		{
			if(lang == undefined)
				lang = "Lua";
			Storage.getOs(function(os)
			{
				if(os == undefined)
					os = "Android";
				vm.$data.langos = lang + "-" + os;
			});
		});
    },
    computed: {
        version: function () {
            /*var locationArr = location.href.split('/');
            return locationArr[3];*/
            return "0.1.1"
        }
    },
    watch: {
        osToggle: function(val)
        {
            this.$data.os = this.$data.languageToggle + "-" + this.$data.osToggle;
        },
        languageToggle: function(val)
        {
            this.$data.os = this.$data.languageToggle + "-" + this.$data.osToggle;
        }
    }

}
</script>